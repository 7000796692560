import { useApolloClient } from "@apollo/client/main.cjs";
import { FormLayout, Modal, Spinner } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { queryShopifyCustomer } from "@smartrr/shared/shopifyGraphQL/customer";
import { captureException } from "@smartrr/shared/utils/captureException";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";

import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import * as S from "./styles";

// Mac overwrites styles for email and it looks like a link

export interface IImportCustomerModal {
  open: boolean;
  shopifyCustomerId: string;
  onClose: () => void;
  setShippingInfo: (arg: ICustomerRelationshipShallow) => Promise<void>;
  updateShopifyPaymentMethodsState: (paymentMethods: IPaymentMethod[]) => void;
}

export function ImportCustomerModal({
  open,
  shopifyCustomerId,
  onClose,
  setShippingInfo,
  updateShopifyPaymentMethodsState,
}: IImportCustomerModal): JSX.Element {
  const { useField, setFieldValue, useValues } = useTypedForm({
    validationSchema: yup.object().shape({
      stripeCustomerId: yup.string(),
      email: yup.string().required(),
      currency: yup.string().required(),
    }),
    initialValues: {
      stripeCustomerId: "",
      email: "",
      currency: "",
    },
    onSubmit: NO_OP_CALLBACK,
  });
  const [isLoading, setLoading] = useState(false);
  const [isImporting, setImporting] = useState(false);
  const [email, setEmail] = useState("");
  const [currency, setCurrency] = useState("");
  const client = useApolloClient();
  const values = useValues();

  const { Input: StripeCustomerIdInput } = useField("stripeCustomerId");
  const { Input: CureencyInput } = useField("currency");
  const { Input: EmailInput } = useField("email");

  const onImport = useCallback(async () => {
    setImporting(true);

    try {
      const customerResponse = await typedFrontendVendorApi.postReq("/customer-relationship/import", {
        reqBody: {
          stripeCustomerId: values.stripeCustomerId,
          currency,
          email,
        },
      });

      if (customerResponse.type === "success") {
        const paymentResponse = await typedFrontendVendorApi.postReq(
          "/customer-relationship/payment-methods/import",
          {
            reqBody: { customerShopifyId: customerResponse.body!.shopifyId },
          }
        );

        if (paymentResponse.type === "success") {
          updateShopifyPaymentMethodsState(paymentResponse.body);
        }

        await setShippingInfo(customerResponse.body);
        onClose();
      }
    } catch (error) {
      captureException("Error importing", error);
    }
    setImporting(false);
  }, [onClose, updateShopifyPaymentMethodsState, email, currency, values.stripeCustomerId]);

  useEffect(() => {
    setFieldValue("email", email);
    setFieldValue("currency", currency);
  }, [email, currency]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      queryShopifyCustomer(shopifyCustomerId, client as any).then(res => {
        if (res.type === "success") {
          setEmail(res.body.data.customer!.email as string);
          setCurrency(res.body.data.customer!.amountSpent.currencyCode || "");
        }
        if (res.type === "error") {
          captureException("Error importing");
        }

        setLoading(false);
      });
    }
  }, [open]);

  return (
    <Modal
      title="Import customer from Shopify"
      open={open}
      onClose={onClose}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
      primaryAction={{
        content: "Import",
        onAction: onImport,
        loading: isLoading || isImporting,
      }}
    >
      <Modal.Section>
        {isLoading ? (
          <Box mt={9} mb={9} justifyContent="center" alignItems="center">
            <Spinner />
          </Box>
        ) : (
          <FormLayout>
            <FormLayout.Group>
              <S.InputWrapper>
                <EmailInput type="email" label="Email" disabled usePolaris />
              </S.InputWrapper>
            </FormLayout.Group>
            <FormLayout.Group>
              <CureencyInput type="text" label="Currency" usePolaris />
            </FormLayout.Group>
            <FormLayout.Group>
              <StripeCustomerIdInput type="text" label="Stripe Id" usePolaris />
            </FormLayout.Group>
          </FormLayout>
        )}
      </Modal.Section>
    </Modal>
  );
}
